import React, { FC, useContext, useEffect, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Col, Empty, Row } from 'antd'
import moment from 'moment'

import LoadingSpinner from '../../../../components/LoadingSpinner'
import { getGoneOrReturn } from '../../../../helpers/travel-page'
import { ALL_TRAVELS } from '../../../../queries'
import { TravelPageContext } from '../../Provider'
import { Travel, TravelListProps } from '../../types'
import TravelDisplay from '../travelDisplay'
import TravelInfo from '../travelInfo'

import styles from './styles.module.css'

const TravelList: FC<TravelListProps> = ({
  date,
  destiny,
  destinyName,
  numberOfSeats,
  origin,
  originName,
  onSelect,
}) => {
  const { hasReturn, integrationTokens, step, transaction } = useContext(
    TravelPageContext,
  )
  const goneOrReturn = getGoneOrReturn(hasReturn, step)

  const [travels, setTravels] = useState<Travel[][]>([])

  const [getTravels, { data, loading }] = useLazyQuery(ALL_TRAVELS, {
    fetchPolicy: 'no-cache',
    variables: {
      allTravelsInput: {
        departureCity: origin,
        destinationCity: destiny,
        departureDate: date,
        goneOrReturn,
        numberOfSeats,
        tokens: integrationTokens,
      },
    },
  })

  useEffect(() => {
    getTravels();
  }, [goneOrReturn]);


  useEffect(() => {
    setTravels([])
    let list:Travel[][] = data?.allTravels ?? []
    if (hasReturn && step > 0) {
      const { travelGone } = transaction
      if (travelGone?.arrives_at){
        list = list.filter((group: Travel[]) => {
          return moment
            .utc(group[0].departs_at, 'DD-MM-YYYY HH:mm')
            .isAfter(moment.utc(travelGone?.arrives_at, 'DD-MM-YYYY HH:mm'))
        })
      }

    }

    const newlist=[]
    for (const listElement of list) {

      const equ = listElement.every(travel => {
        return travel.seat_class === listElement[0].seat_class
      })
      if (equ && listElement.length > 1) {
        newlist.push(listElement)
        newlist.push([...listElement].reverse())
      }else
        newlist.push(listElement)

    }
    setTravels(newlist)

  },[data]);

  if (loading)
    return (
      <div className={styles.loading}>
        <LoadingSpinner size="100px" />
      </div>
    )

  if (!data || !data.allTravels)
    return (
      <div className={styles.empty}>
        <Empty />
      </div>
    )

  return (
    <Row>
      <Col span={24} className={styles.travelList}>
        <>
          <TravelInfo
            date={date}
            destiny={destinyName}
            origin={originName}
            amountOfTrips={travels.length}
          />
          {travels.map((travelGroup: Travel[]) => (
            <TravelDisplay
              key={travelGroup[0].id}
              travelGroup={travelGroup}
              onSelectButton={onSelect}
            />
          ))}
        </>
      </Col>
    </Row>
  )
}

export default TravelList
